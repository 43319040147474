import type { OrganizationMembershipsUpdate } from '@orus.eu/backend/src/access/membership-store'
import { TechnicalError } from '@orus.eu/error'
import type { OrganizationMembership } from '@orus.eu/right-access-management'
import deepEqual from 'deep-equal'

export type OrganizationMembershipsDiff = {
  /**
   * Technical definition of the update
   */
  update: OrganizationMembershipsUpdate
  /**
   * UI-friendly description of groups that were added in the process
   */
  addedOrganizationMemberships: OrganizationMembership[]
  /**
   * UI-friendly description of groups that were removed in the process
   */
  removedOrganizationMemberships: OrganizationMembership[]
  /**
   * UI-friendly description of groups that were updated in the process
   */
  updatedOrganizationMemberships: OrganizationMembership[]
}

export function isOrganizationMembershipDiffEmpty(diff: OrganizationMembershipsDiff): boolean {
  return Object.keys(diff.update).length === 0
}

export function computeOrganizationMembershipDiff(
  a: OrganizationMembershipsUpdate,
  b: OrganizationMembershipsUpdate,
): OrganizationMembershipsDiff {
  const diff: OrganizationMembershipsDiff = {
    update: {},
    addedOrganizationMemberships: [],
    removedOrganizationMemberships: [],
    updatedOrganizationMemberships: [],
  }

  const unseenOldOrganizationMembershipEmails = new Set(Object.keys(a))

  for (const entry of Object.entries(b)) {
    const email = entry[0]
    const definitionA = a[email]
    const definitionB = entry[1]

    unseenOldOrganizationMembershipEmails.delete(email)

    const { organizations, deleted } = definitionB

    if (definitionA && !definitionA.deleted && definitionB.deleted) {
      diff.update[email] = definitionB
      diff.removedOrganizationMemberships.push({
        email,
        organizations,
        deleted,
      })
      continue
    }

    if ((!definitionA || definitionA.deleted) && !deleted) {
      diff.update[email] = definitionB
      diff.addedOrganizationMemberships.push({
        email,
        organizations,
        deleted,
      })
      continue
    }

    if (
      !definitionA ||
      definitionA.deleted !== definitionB.deleted ||
      !deepEqual(definitionA.organizations, definitionB.organizations)
    ) {
      diff.update[email] = definitionB
      diff.updatedOrganizationMemberships.push({
        email,
        organizations,
        deleted,
      })
      continue
    }

    if (!deepEqual(definitionA, definitionB)) {
      throw new TechnicalError('Unhandled difference between group definitions', {
        context: { definitionA, definitionB },
      })
    }
  }

  for (const email of unseenOldOrganizationMembershipEmails) {
    const definitionA = a[email]
    const { organizations, deleted } = definitionA

    diff.update[email] = { ...definitionA, deleted: true }
    diff.removedOrganizationMemberships.push({
      email,
      organizations,
      deleted,
    })
  }

  return diff
}
