import { css } from '@emotion/react'
import { TechnicalError } from '@orus.eu/error'
import { Button, sectionDelimitersBorder, spacing, useAsyncCallback, useCrash, useEnqueueToast } from '@orus.eu/pharaoh'
import { isFailure } from '@orus.eu/result'
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { trpc, trpcReact } from '../../../../../client'
import { useNavigateTo } from '../../../../../lib/hooks/use-navigate-to-route'
import { GlobalLoadingState } from '../../../../molecules/global-loading-state'
import { ensureMonacoWorkerInitialized } from '../platform-editor/monaco-worker'
import {
  computeOrganizationMembershipDiff,
  isOrganizationMembershipDiffEmpty,
  type OrganizationMembershipsDiff,
} from './memberships-diff'
import { formatOrganizationMembershipsUpdate, parseOrganizationMembershipsUpdate } from './memberships-formatting'
import { MembershipsReviewDialog } from './memberships-review-dialog'

import type { OrganizationMembershipsUpdate } from '@orus.eu/backend/src/access/membership-store'
import { usePermissions } from '../../../../../lib/use-permissions'

export const MembershipsEditor = memo(function OrganizationEditor() {
  const crash = useCrash()
  const permissions = usePermissions()
  const [editor, setEditor] = useState<monaco.editor.IStandaloneCodeEditor | null>(null)
  const monacoEl = useRef(null)
  const { enqueueToast } = useEnqueueToast()
  const organizationMembershipsApi = trpcReact.organizations.getOrganizationsMemberships.useQuery()
  const [submitting, setSubmitting] = useState(false)
  const navigateToBackofficeHome = useNavigateTo({ to: '/bak/home' })
  const initialOrganizations =
    organizationMembershipsApi.status === 'success' && organizationMembershipsApi.data
      ? organizationMembershipsApi.data
      : undefined
  const [changesToReview, setChangesToReview] = useState<OrganizationMembershipsDiff | undefined>(undefined)
  const initialEditorContent =
    organizationMembershipsApi.status === 'success' && organizationMembershipsApi.data
      ? formatOrganizationMembershipsUpdate(organizationMembershipsApi.data)
      : undefined

  useEffect(() => {
    if (monacoEl) {
      setEditor((editor) => {
        if (editor) return editor

        const currentMonacoEl = monacoEl.current
        if (!currentMonacoEl) {
          crash({ type: 'unexpected-error', err: new TechnicalError('expected monacoEl.current to be set') })
          return null
        }

        ensureMonacoWorkerInitialized()

        return monaco.editor.create(currentMonacoEl, {
          value: '', // content loaded asynchronously from server
          language: 'json',
          automaticLayout: true,
        })
      })
    }

    return () => editor?.dispose()
  }, [crash, editor])

  useEffect(() => {
    if (initialEditorContent && editor) {
      editor.setValue(initialEditorContent)
    }
  }, [editor, initialEditorContent])

  const cancelChanges = useCallback(() => {
    if (initialEditorContent && editor) {
      editor.setValue(initialEditorContent)
    }
  }, [editor, initialEditorContent])

  const format = useCallback(() => {
    if (!editor) return

    const stringValue = editor.getValue()
    const parsingResult = parseOrganizationMembershipsUpdate(stringValue)
    if (isFailure(parsingResult)) {
      enqueueToast(parsingResult.problem, { variant: 'danger' })
      return
    }
    editor.setValue(formatOrganizationMembershipsUpdate(parsingResult.output))
  }, [editor, enqueueToast])

  const closeReviewDialog = useCallback(() => {
    setChangesToReview(undefined)
  }, [])

  const openReviewDialog = useCallback(() => {
    if (!editor || !initialOrganizations) return

    const stringValue = editor.getValue()
    const parsingResult = parseOrganizationMembershipsUpdate(stringValue)
    if (isFailure(parsingResult)) {
      enqueueToast(parsingResult.problem, { variant: 'danger' })
      return
    }
    const diff = computeOrganizationMembershipDiff(initialOrganizations, parsingResult.output)
    if (isOrganizationMembershipDiffEmpty(diff)) {
      enqueueToast("Il n'y a aucun changement !", {})
      return
    }

    setChangesToReview(diff)
  }, [editor, enqueueToast, initialOrganizations])

  const submitChanges = useAsyncCallback(
    async (changes: OrganizationMembershipsUpdate) => {
      setSubmitting(true)
      const result = await trpc.organizations.applyOrganizationsMembershipsChange.mutate(changes)
      if (isFailure(result)) {
        enqueueToast(result.problem.message, { variant: 'danger' })
        navigateToBackofficeHome()
        return
      }
      enqueueToast('Changements pour les adhésions sauvés et actifs !', { variant: 'success' })
      navigateToBackofficeHome()
    },
    [enqueueToast, navigateToBackofficeHome],
  )

  if (submitting) {
    return <GlobalLoadingState />
  }

  return (
    <>
      <div
        css={css`
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            flex-grow: 0;
            flex-shrink: 0;
            padding: 0 ${spacing[60]} ${spacing[40]} ${spacing[60]};
            display: flex;
            gap: ${spacing[40]};
          `}
        >
          <Button
            icon="arrow-rotate-left-solid"
            size="small"
            avatarPosition="left"
            variant="secondary"
            onClick={cancelChanges}
          >
            Annuler les modifications
          </Button>
          <Button icon="file-lines-regular" size="small" avatarPosition="left" variant="secondary" onClick={format}>
            Formatter
          </Button>
          {permissions.permissions.includes('organization.edit') ? (
            <Button
              icon="magnifying-glass-solid"
              size="small"
              avatarPosition="left"
              variant="primary"
              onClick={openReviewDialog}
            >
              Revoir et tester les changements
            </Button>
          ) : undefined}
        </div>
        <div
          css={css`
            flex-grow: 1;
            flex-shrink: 1;
            border-top: ${sectionDelimitersBorder};
          `}
          ref={monacoEl}
        />
      </div>
      {changesToReview ? (
        <MembershipsReviewDialog onSubmit={submitChanges} diff={changesToReview} onClose={closeReviewDialog} />
      ) : (
        <></>
      )}
    </>
  )
})
