import type { OrganizationMembershipsUpdate } from '@orus.eu/backend/src/access/membership-store'
import { failure, isFailure, success, type Result } from '@orus.eu/result'
import { organizationMembershipsDefinitionSchema } from '@orus.eu/right-access-management'

export function formatOrganizationMembershipsUpdate(value: OrganizationMembershipsUpdate): string {
  const sortedKeys = Object.keys(value).sort()
  const sortedOrganizations = Object.fromEntries(
    sortedKeys.map((key) => {
      return [key, value[key]]
    }),
  )
  return JSON.stringify(sortedOrganizations, null, 2)
}

export function parseOrganizationMembershipsUpdate(dataString: string): Result<OrganizationMembershipsUpdate, string> {
  let parsedDataString = ''
  try {
    parsedDataString = JSON.parse(dataString)
  } catch (_err: unknown) {
    return failure("La syntaxe JSON n'est pas respectée. Corrige d'abord les lignes en rouge")
  }

  const validationResult = validateOrganizationMembershipsUpdate(parsedDataString)
  if (isFailure(validationResult)) return validationResult

  return success(validationResult.output)
}

function validateOrganizationMembershipsUpdate(data: unknown): Result<OrganizationMembershipsUpdate, string> {
  const zodResult = organizationMembershipsDefinitionSchema.safeParse(data)
  if (zodResult.success) return success(zodResult.data)
  return failure(`Le format des données n'est pas respecté. Détails : ${zodResult.error.message}`)
}
